import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f34227f0 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _15e90eb4 = () => interopDefault(import('../pages/case-studies.vue' /* webpackChunkName: "pages/case-studies" */))
const _005500e6 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _c40cecfe = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _20c81018 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2cbbd8de = () => interopDefault(import('../pages/filipino-workforce.vue' /* webpackChunkName: "pages/filipino-workforce" */))
const _21a381cb = () => interopDefault(import('../pages/golden-visa.vue' /* webpackChunkName: "pages/golden-visa" */))
const _403d7f24 = () => interopDefault(import('../pages/indian-workforce.vue' /* webpackChunkName: "pages/indian-workforce" */))
const _0e70a0bc = () => interopDefault(import('../pages/job-seeker/index.vue' /* webpackChunkName: "pages/job-seeker/index" */))
const _1753b540 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0b234841 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _517d20f0 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _4be831db = () => interopDefault(import('../pages/job-seeker/advice/index.vue' /* webpackChunkName: "pages/job-seeker/advice/index" */))
const _522a3420 = () => interopDefault(import('../pages/job-seeker/contact.vue' /* webpackChunkName: "pages/job-seeker/contact" */))
const _665e748e = () => interopDefault(import('../pages/job-seeker/jobs/index.vue' /* webpackChunkName: "pages/job-seeker/jobs/index" */))
const _66a83ebc = () => interopDefault(import('../pages/job-seeker/advice/preview/_advice_preview.vue' /* webpackChunkName: "pages/job-seeker/advice/preview/_advice_preview" */))
const _a28b2a52 = () => interopDefault(import('../pages/job-seeker/jobs/preview/_job_preview.vue' /* webpackChunkName: "pages/job-seeker/jobs/preview/_job_preview" */))
const _405d7f40 = () => interopDefault(import('../pages/job-seeker/advice/_advice.vue' /* webpackChunkName: "pages/job-seeker/advice/_advice" */))
const _262d0567 = () => interopDefault(import('../pages/job-seeker/jobs/_job.vue' /* webpackChunkName: "pages/job-seeker/jobs/_job" */))
const _a0acb1ae = () => interopDefault(import('../pages/news/preview/_article_preview.vue' /* webpackChunkName: "pages/news/preview/_article_preview" */))
const _ddcc2f8e = () => interopDefault(import('../pages/news/_article.vue' /* webpackChunkName: "pages/news/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _f34227f0,
    name: "about-us___en___default"
  }, {
    path: "/case-studies",
    component: _15e90eb4,
    name: "case-studies___en___default"
  }, {
    path: "/contact",
    component: _005500e6,
    name: "contact___en___default"
  }, {
    path: "/cookie-policy",
    component: _c40cecfe,
    name: "cookie-policy___en___default"
  }, {
    path: "/de",
    component: _20c81018,
    name: "index___de"
  }, {
    path: "/en",
    component: _20c81018,
    name: "index___en"
  }, {
    path: "/filipino-workforce",
    component: _2cbbd8de,
    name: "filipino-workforce___en___default"
  }, {
    path: "/golden-visa",
    component: _21a381cb,
    name: "golden-visa___en___default"
  }, {
    path: "/hu",
    component: _20c81018,
    name: "index___hu"
  }, {
    path: "/indian-workforce",
    component: _403d7f24,
    name: "indian-workforce___en___default"
  }, {
    path: "/job-seeker",
    component: _0e70a0bc,
    name: "job-seeker___en___default"
  }, {
    path: "/ko",
    component: _20c81018,
    name: "index___ko"
  }, {
    path: "/news",
    component: _1753b540,
    name: "news___en___default"
  }, {
    path: "/privacy-policy",
    component: _0b234841,
    name: "privacy-policy___en___default"
  }, {
    path: "/services",
    component: _517d20f0,
    name: "services___en___default"
  }, {
    path: "/zh",
    component: _20c81018,
    name: "index___zh"
  }, {
    path: "/de/about-us",
    component: _f34227f0,
    name: "about-us___de"
  }, {
    path: "/de/case-studies",
    component: _15e90eb4,
    name: "case-studies___de"
  }, {
    path: "/de/contact",
    component: _005500e6,
    name: "contact___de"
  }, {
    path: "/de/cookie-policy",
    component: _c40cecfe,
    name: "cookie-policy___de"
  }, {
    path: "/de/filipino-workforce",
    component: _2cbbd8de,
    name: "filipino-workforce___de"
  }, {
    path: "/de/golden-visa",
    component: _21a381cb,
    name: "golden-visa___de"
  }, {
    path: "/de/indian-workforce",
    component: _403d7f24,
    name: "indian-workforce___de"
  }, {
    path: "/de/job-seeker",
    component: _0e70a0bc,
    name: "job-seeker___de"
  }, {
    path: "/de/news",
    component: _1753b540,
    name: "news___de"
  }, {
    path: "/de/privacy-policy",
    component: _0b234841,
    name: "privacy-policy___de"
  }, {
    path: "/de/services",
    component: _517d20f0,
    name: "services___de"
  }, {
    path: "/en/about-us",
    component: _f34227f0,
    name: "about-us___en"
  }, {
    path: "/en/case-studies",
    component: _15e90eb4,
    name: "case-studies___en"
  }, {
    path: "/en/contact",
    component: _005500e6,
    name: "contact___en"
  }, {
    path: "/en/cookie-policy",
    component: _c40cecfe,
    name: "cookie-policy___en"
  }, {
    path: "/en/filipino-workforce",
    component: _2cbbd8de,
    name: "filipino-workforce___en"
  }, {
    path: "/en/golden-visa",
    component: _21a381cb,
    name: "golden-visa___en"
  }, {
    path: "/en/indian-workforce",
    component: _403d7f24,
    name: "indian-workforce___en"
  }, {
    path: "/en/job-seeker",
    component: _0e70a0bc,
    name: "job-seeker___en"
  }, {
    path: "/en/news",
    component: _1753b540,
    name: "news___en"
  }, {
    path: "/en/privacy-policy",
    component: _0b234841,
    name: "privacy-policy___en"
  }, {
    path: "/en/services",
    component: _517d20f0,
    name: "services___en"
  }, {
    path: "/hu/about-us",
    component: _f34227f0,
    name: "about-us___hu"
  }, {
    path: "/hu/case-studies",
    component: _15e90eb4,
    name: "case-studies___hu"
  }, {
    path: "/hu/contact",
    component: _005500e6,
    name: "contact___hu"
  }, {
    path: "/hu/cookie-policy",
    component: _c40cecfe,
    name: "cookie-policy___hu"
  }, {
    path: "/hu/filipino-workforce",
    component: _2cbbd8de,
    name: "filipino-workforce___hu"
  }, {
    path: "/hu/golden-visa",
    component: _21a381cb,
    name: "golden-visa___hu"
  }, {
    path: "/hu/indian-workforce",
    component: _403d7f24,
    name: "indian-workforce___hu"
  }, {
    path: "/hu/job-seeker",
    component: _0e70a0bc,
    name: "job-seeker___hu"
  }, {
    path: "/hu/news",
    component: _1753b540,
    name: "news___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _0b234841,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/services",
    component: _517d20f0,
    name: "services___hu"
  }, {
    path: "/job-seeker/advice",
    component: _4be831db,
    name: "job-seeker-advice___en___default"
  }, {
    path: "/job-seeker/contact",
    component: _522a3420,
    name: "job-seeker-contact___en___default"
  }, {
    path: "/job-seeker/jobs",
    component: _665e748e,
    name: "job-seeker-jobs___en___default"
  }, {
    path: "/ko/about-us",
    component: _f34227f0,
    name: "about-us___ko"
  }, {
    path: "/ko/case-studies",
    component: _15e90eb4,
    name: "case-studies___ko"
  }, {
    path: "/ko/contact",
    component: _005500e6,
    name: "contact___ko"
  }, {
    path: "/ko/cookie-policy",
    component: _c40cecfe,
    name: "cookie-policy___ko"
  }, {
    path: "/ko/filipino-workforce",
    component: _2cbbd8de,
    name: "filipino-workforce___ko"
  }, {
    path: "/ko/golden-visa",
    component: _21a381cb,
    name: "golden-visa___ko"
  }, {
    path: "/ko/indian-workforce",
    component: _403d7f24,
    name: "indian-workforce___ko"
  }, {
    path: "/ko/job-seeker",
    component: _0e70a0bc,
    name: "job-seeker___ko"
  }, {
    path: "/ko/news",
    component: _1753b540,
    name: "news___ko"
  }, {
    path: "/ko/privacy-policy",
    component: _0b234841,
    name: "privacy-policy___ko"
  }, {
    path: "/ko/services",
    component: _517d20f0,
    name: "services___ko"
  }, {
    path: "/zh/about-us",
    component: _f34227f0,
    name: "about-us___zh"
  }, {
    path: "/zh/case-studies",
    component: _15e90eb4,
    name: "case-studies___zh"
  }, {
    path: "/zh/contact",
    component: _005500e6,
    name: "contact___zh"
  }, {
    path: "/zh/cookie-policy",
    component: _c40cecfe,
    name: "cookie-policy___zh"
  }, {
    path: "/zh/filipino-workforce",
    component: _2cbbd8de,
    name: "filipino-workforce___zh"
  }, {
    path: "/zh/golden-visa",
    component: _21a381cb,
    name: "golden-visa___zh"
  }, {
    path: "/zh/indian-workforce",
    component: _403d7f24,
    name: "indian-workforce___zh"
  }, {
    path: "/zh/job-seeker",
    component: _0e70a0bc,
    name: "job-seeker___zh"
  }, {
    path: "/zh/news",
    component: _1753b540,
    name: "news___zh"
  }, {
    path: "/zh/privacy-policy",
    component: _0b234841,
    name: "privacy-policy___zh"
  }, {
    path: "/zh/services",
    component: _517d20f0,
    name: "services___zh"
  }, {
    path: "/de/job-seeker/advice",
    component: _4be831db,
    name: "job-seeker-advice___de"
  }, {
    path: "/de/job-seeker/contact",
    component: _522a3420,
    name: "job-seeker-contact___de"
  }, {
    path: "/de/job-seeker/jobs",
    component: _665e748e,
    name: "job-seeker-jobs___de"
  }, {
    path: "/en/job-seeker/advice",
    component: _4be831db,
    name: "job-seeker-advice___en"
  }, {
    path: "/en/job-seeker/contact",
    component: _522a3420,
    name: "job-seeker-contact___en"
  }, {
    path: "/en/job-seeker/jobs",
    component: _665e748e,
    name: "job-seeker-jobs___en"
  }, {
    path: "/hu/job-seeker/advice",
    component: _4be831db,
    name: "job-seeker-advice___hu"
  }, {
    path: "/hu/job-seeker/contact",
    component: _522a3420,
    name: "job-seeker-contact___hu"
  }, {
    path: "/hu/job-seeker/jobs",
    component: _665e748e,
    name: "job-seeker-jobs___hu"
  }, {
    path: "/ko/job-seeker/advice",
    component: _4be831db,
    name: "job-seeker-advice___ko"
  }, {
    path: "/ko/job-seeker/contact",
    component: _522a3420,
    name: "job-seeker-contact___ko"
  }, {
    path: "/ko/job-seeker/jobs",
    component: _665e748e,
    name: "job-seeker-jobs___ko"
  }, {
    path: "/zh/job-seeker/advice",
    component: _4be831db,
    name: "job-seeker-advice___zh"
  }, {
    path: "/zh/job-seeker/contact",
    component: _522a3420,
    name: "job-seeker-contact___zh"
  }, {
    path: "/zh/job-seeker/jobs",
    component: _665e748e,
    name: "job-seeker-jobs___zh"
  }, {
    path: "/",
    component: _20c81018,
    name: "index___en___default"
  }, {
    path: "/de/job-seeker/advice/preview/:advice_preview",
    component: _66a83ebc,
    name: "job-seeker-advice-preview-advice_preview___de"
  }, {
    path: "/de/job-seeker/jobs/preview/:job_preview",
    component: _a28b2a52,
    name: "job-seeker-jobs-preview-job_preview___de"
  }, {
    path: "/en/job-seeker/advice/preview/:advice_preview",
    component: _66a83ebc,
    name: "job-seeker-advice-preview-advice_preview___en"
  }, {
    path: "/en/job-seeker/jobs/preview/:job_preview",
    component: _a28b2a52,
    name: "job-seeker-jobs-preview-job_preview___en"
  }, {
    path: "/hu/job-seeker/advice/preview/:advice_preview",
    component: _66a83ebc,
    name: "job-seeker-advice-preview-advice_preview___hu"
  }, {
    path: "/hu/job-seeker/jobs/preview/:job_preview",
    component: _a28b2a52,
    name: "job-seeker-jobs-preview-job_preview___hu"
  }, {
    path: "/ko/job-seeker/advice/preview/:advice_preview",
    component: _66a83ebc,
    name: "job-seeker-advice-preview-advice_preview___ko"
  }, {
    path: "/ko/job-seeker/jobs/preview/:job_preview",
    component: _a28b2a52,
    name: "job-seeker-jobs-preview-job_preview___ko"
  }, {
    path: "/zh/job-seeker/advice/preview/:advice_preview",
    component: _66a83ebc,
    name: "job-seeker-advice-preview-advice_preview___zh"
  }, {
    path: "/zh/job-seeker/jobs/preview/:job_preview",
    component: _a28b2a52,
    name: "job-seeker-jobs-preview-job_preview___zh"
  }, {
    path: "/de/job-seeker/advice/:advice",
    component: _405d7f40,
    name: "job-seeker-advice-advice___de"
  }, {
    path: "/de/job-seeker/jobs/:job",
    component: _262d0567,
    name: "job-seeker-jobs-job___de"
  }, {
    path: "/de/news/preview/:article_preview?",
    component: _a0acb1ae,
    name: "news-preview-article_preview___de"
  }, {
    path: "/en/job-seeker/advice/:advice",
    component: _405d7f40,
    name: "job-seeker-advice-advice___en"
  }, {
    path: "/en/job-seeker/jobs/:job",
    component: _262d0567,
    name: "job-seeker-jobs-job___en"
  }, {
    path: "/en/news/preview/:article_preview?",
    component: _a0acb1ae,
    name: "news-preview-article_preview___en"
  }, {
    path: "/hu/job-seeker/advice/:advice",
    component: _405d7f40,
    name: "job-seeker-advice-advice___hu"
  }, {
    path: "/hu/job-seeker/jobs/:job",
    component: _262d0567,
    name: "job-seeker-jobs-job___hu"
  }, {
    path: "/hu/news/preview/:article_preview?",
    component: _a0acb1ae,
    name: "news-preview-article_preview___hu"
  }, {
    path: "/job-seeker/advice/preview/:advice_preview",
    component: _66a83ebc,
    name: "job-seeker-advice-preview-advice_preview___en___default"
  }, {
    path: "/job-seeker/jobs/preview/:job_preview",
    component: _a28b2a52,
    name: "job-seeker-jobs-preview-job_preview___en___default"
  }, {
    path: "/ko/job-seeker/advice/:advice",
    component: _405d7f40,
    name: "job-seeker-advice-advice___ko"
  }, {
    path: "/ko/job-seeker/jobs/:job",
    component: _262d0567,
    name: "job-seeker-jobs-job___ko"
  }, {
    path: "/ko/news/preview/:article_preview?",
    component: _a0acb1ae,
    name: "news-preview-article_preview___ko"
  }, {
    path: "/zh/job-seeker/advice/:advice",
    component: _405d7f40,
    name: "job-seeker-advice-advice___zh"
  }, {
    path: "/zh/job-seeker/jobs/:job",
    component: _262d0567,
    name: "job-seeker-jobs-job___zh"
  }, {
    path: "/zh/news/preview/:article_preview?",
    component: _a0acb1ae,
    name: "news-preview-article_preview___zh"
  }, {
    path: "/de/news/:article",
    component: _ddcc2f8e,
    name: "news-article___de"
  }, {
    path: "/en/news/:article",
    component: _ddcc2f8e,
    name: "news-article___en"
  }, {
    path: "/hu/news/:article",
    component: _ddcc2f8e,
    name: "news-article___hu"
  }, {
    path: "/job-seeker/advice/:advice",
    component: _405d7f40,
    name: "job-seeker-advice-advice___en___default"
  }, {
    path: "/job-seeker/jobs/:job",
    component: _262d0567,
    name: "job-seeker-jobs-job___en___default"
  }, {
    path: "/ko/news/:article",
    component: _ddcc2f8e,
    name: "news-article___ko"
  }, {
    path: "/news/preview/:article_preview?",
    component: _a0acb1ae,
    name: "news-preview-article_preview___en___default"
  }, {
    path: "/zh/news/:article",
    component: _ddcc2f8e,
    name: "news-article___zh"
  }, {
    path: "/news/:article",
    component: _ddcc2f8e,
    name: "news-article___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
