<template>
    <div :class="illustration && !secondaryType ? 'pb-36 sm:pb-16' : 'pb-16'">
        <div
            class="
                relative
                h-full
                pt-24
                xl:pt-28
                2xl:pt-32
                rounded-br-200
                md:rounded-br-[250px]
                xl:rounded-br-[350px]
                purple-gradient-bg
            "
            :class="{ 'overflow-hidden': secondaryType }"
        >
            <div
                class="
                    absolute
                    w-full
                    h-full
                    top-0
                    left-0
                    bg-cover bg-no-repeat bg-center
                    z-0
                    rounded-br-200
                    md:rounded-br-[250px]
                    xl:rounded-br-[350px]
                "
                :class="{ 'golden-visa-bg': isGoldenVisaPage }"
                :style="`background-image: url(${
                    backgroundImage || require('assets/images/illustrations/logo-as-hero-bg-shape.svg')
                });`"
            ></div>
            <div
                class="relative container grid grid-cols-12 gap-5 z-5"
                :class="{ 'pb-12 sm:pb-20 lg:pb-24': overlappedByContent }"
            >
                <div
                    class="col-span-12"
                    :class="[
                        illustration ? 'lg:pb-28  lg:pr-0 sm:pb-18' : 'pb-18 sm:pr-0',
                        illustration && !secondaryType ? 'sm:pr-20' : '',
                        secondaryType ? 'sm:col-span-6 lg:col-span-7 lg:pr-12' : 'sm:col-span-8 pr-12',
                    ]"
                >
                    <h1
                        class="aux-text mt-4 sm:mt-8 xl:mt-12 2xl:mt-16 mb-4 transform-all"
                        :class="[!mounted && isIndexPage ? 'translate-y-4 opacity-0' : '']"
                        style="transition-duration: 500ms; transition-delay: 50ms"
                    >
                        {{ title }}
                    </h1>

                    <h2
                        class="title title-xl"
                        :class="[
                            isGoldenVisaPage
                                ? 'w-72 lg:w-auto lg:max-w-sm lg:pr-8 xl:max-w-md 2xl:pr-0'
                                : illustration
                                ? 'max-w-3xl'
                                : 'max-w-4xl',
                            !mounted && isIndexPage ? 'translate-y-4 opacity-0' : '',
                        ]"
                        style="transition-duration: 500ms; transition-delay: 100ms"
                    >
                        {{ lead }}
                    </h2>

                    <p
                        v-if="description"
                        class="mt-6 max-w-3xl"
                        :class="{
                            'translate-y-4 opacity-0': !mounted && isIndexPage,
                            'pr-20': !secondaryType,
                            'xl:max-w-2xl': isIndexPage,
                            'pr-12 sm:pr-0 sm:max-w-md': isGoldenVisaPage,
                        }"
                        style="transition-duration: 500ms; transition-delay: 150ms"
                    >
                        {{ description }}
                    </p>

                    <div
                        v-if="primaryButton || secondaryButton"
                        class="flex flex-col items-start lg:flex-row mt-8 sm:mt-16 lg:mt-24"
                    >
                        <NuxtLink
                            v-if="primaryButton"
                            :to="primaryButton.url"
                            class="p-component p-button p-button-secondary"
                            :class="{
                                'mb-5 lg:mb-0 lg:mr-5': secondaryButton,
                                'translate-y-4 opacity-0': !mounted && isIndexPage,
                            }"
                            :style="
                                buttonsHaveDelayedAnimation ? `transition-duration: 500ms; transition-delay: 150ms` : ''
                            "
                        >
                            {{ primaryButton.label }}
                        </NuxtLink>
                        <NuxtLink
                            v-if="secondaryButton"
                            :to="secondaryButton.url"
                            class="p-component p-button p-button-secondary p-button-outlined p-button-light"
                            :class="{ 'translate-y-4 opacity-0': !mounted && isIndexPage }"
                            :style="
                                buttonsHaveDelayedAnimation ? `transition-duration: 500ms; transition-delay: 200ms` : ''
                            "
                        >
                            {{ secondaryButton.label }}
                        </NuxtLink>
                    </div>
                </div>

                <slot name="right"></slot>

                <Transition name="fade-left">
                    <div
                        v-if="illustration && mounted"
                        class="relative sm:h-auto col-span-12"
                        :class="[
                            secondaryType
                                ? 'h-72 sm:col-span-6 lg:col-span-5 lg:col-start-8'
                                : 'h-56 sm:col-span-4 sm:col-start-9',
                        ]"
                        style="transition-duration: 700ms; transition-timing-function: cubic-bezier(0.23, 0.15, 0, 1)"
                    >
                        <div
                            class="pl-2 sm:pl-0 sm:absolute sm:top-0 sm:left-0 w-full"
                            :class="[secondaryType ? 'h-full' : 'hero-illustration md:pb-28 xl:pb-0']"
                        >
                            <div
                                class="
                                    relative
                                    h-full
                                    md:max-h-[400px]
                                    lg:max-h-[unset]
                                    xs:max-w-[300px]
                                    md:max-w-[500px]
                                "
                                :class="[secondaryType ? 'max-h-[360px]' : 'max-h-[340px]']"
                            >
                                <div
                                    class="absolute w-full h-full bg-left-bottom bg-contain bg-no-repeat z-10"
                                    :style="`background-image: url(${illustration})`"
                                ></div>
                                <div
                                    v-if="decoration"
                                    class="
                                        absolute
                                        w-32
                                        h-22
                                        sm:w-44 sm:h-32
                                        bg-left-bottom bg-contain bg-no-repeat
                                        z-0
                                    "
                                    :class="[
                                        secondaryType
                                            ? 'right-5 xs:right-8 md:right-5 xl:right-12 bottom-28 md:bottom-32 xl:bottom-36'
                                            : '-bottom-8 sm:-bottom-10 -left-3 sm:-left-12',
                                    ]"
                                    :style="`background-image: url(${decoration})`"
                                ></div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Hero',
    props: {
        title: {
            type: String,
            required: true,
        },
        lead: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        primaryButton: {
            type: Object,
            required: false,
            default: null,
        },
        secondaryButton: {
            type: Object,
            required: false,
            default: null,
        },
        backgroundImage: {
            type: String,
            required: false,
            default: null,
        },
        illustration: {
            type: String,
            required: false,
            default: null,
        },
        decoration: {
            type: String,
            required: false,
            default: null,
        },
        overlappedByContent: {
            type: Boolean,
            required: false,
            default: false,
        },
        secondaryType: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            mounted: false,
            buttonsHaveDelayedAnimation: true,
        };
    },
    computed: {
        isGoldenVisaPage() {
            return ['golden-visa'].includes(this.getRouteBaseName());
        },
        isIndexPage() {
            return ['index', 'job-seeker'].includes(this.getRouteBaseName());
        },
    },
    mounted() {
        this.mounted = true;
        setTimeout(() => {
            this.buttonsHaveDelayedAnimation = false;
        }, 700);
    },
};
</script>

<style scoped>
.hero-illustration {
    height: 150%;
    @media (min-width: 640px) {
        height: calc(100% + 1rem);
    }
}

.golden-visa-bg {
    @apply opacity-30 sm:opacity-100;
    @media (max-width: 767px) {
        background-position: 70% 80% !important;
    }
}
</style>
