<template>
    <div class="swiper-container relative">
        <div ref="swiper" class="swiper">
            <div ref="swiperWrapper" class="swiper-wrapper">
                <div v-for="(service, i) in services" :key="i" class="swiper-slide">
                    <ServiceCard
                        :title="service.title"
                        :pictogram="service.icon"
                        :url="{ path: localePath('/services'), hash: service.slug }"
                    />
                </div>
            </div>
        </div>

        <div class="swiper-nav-container">
            <div ref="prevBtn" class="swiper-button-prev"></div>
            <div ref="pagination" class="swiper-pagination"></div>
            <div ref="nextBtn" class="swiper-button-next"></div>
        </div>
    </div>
</template>

<script>
import ServiceCard from '@/components/Cards/ServiceCard/ServiceCard';

export default {
    name: 'ServicesCarousel',
    components: {
        ServiceCard,
    },
    props: {
        services: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            servicesCarousel: null,
        };
    },
    mounted() {
        const breakpoints = {
            530: {
                slidesPerView: 2.2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
            1440: {
                slidesPerView: this.services.length > 4 ? 5 : 4,
            },
        };

        this.servicesCarousel = new this.$swiper(this.$refs.swiper, {
            slidesPerView: 1.2,
            spaceBetween: 20,
            resistance: true,
            resistanceRatio: 0,
            allowTouchMove: this.$device.isMobileOrTablet || window.innerWidth < 768,
            speed: 500,
            breakpoints,
            pagination: {
                el: this.$refs.pagination,
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: this.$refs.nextBtn,
                prevEl: this.$refs.prevBtn,
            },
        });
    },
};
</script>

<style scoped></style>
