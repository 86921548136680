<template>
    <div class="relative section-wrapper">
        <h2
            class="title title-lg decor-underline decor-underline--magenta mb-12 max-w-xl"
            :class="{
                'px-5 sm:px-0': isCarouselWrapper,
                'sm:text-center mx-auto': centeredTitle,
                'text-black': themeLight,
            }"
        >
            {{ title }}
        </h2>

        <slot></slot>

        <div v-if="link" class="text-center mt-12 sm:mt-0 sm:absolute sm:top-0 sm:right-0">
            <NuxtLink
                :to="link.path"
                class="p-button p-button-secondary p-button-text"
                :class="{ 'p-button-light': !themeLight }"
            >
                <span class="pi pi-arrow-right p-button-icon p-button-icon-right"></span>
                <span class="p-button-label">{{ link.label }}</span>
            </NuxtLink>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionWrapper',
    props: {
        title: {
            type: String,
            required: true,
        },
        centeredTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        link: {
            type: Object,
            required: false,
            default: null,
        },
        isCarouselWrapper: {
            type: Boolean,
            required: false,
            default: false,
        },
        themeLight: {
            type: Boolean,
            required: false,
        },
    },
};
</script>

<style scoped></style>
