<template>
    <footer class="relative bg-gray-800 pt-20 md:pt-22 lg:pt-24 pb-14 rounded-tr-100">
        <div class="container">
            <div class="grid grid-cols-12 gap-5 mb-14 lg:mb-16 text-sm md:text-base">
                <div class="col-span-12 md:col-span-6 lg:col-span-3 flex flex-col items-start mb-8 lg:mb-0">
                    <h3 class="font-bold mb-4 lg:mb-3">
                        <NuxtLink :to="localePath('/')">{{ $t('navigation.companies') }}</NuxtLink>
                    </h3>
                    <NuxtLink class="mb-4 lg:mb-3 animated-underline" :to="localePath('/services')">
                        {{ $t('solutions.title') }}
                    </NuxtLink>
                    <NuxtLink class="mb-4 lg:mb-3 animated-underline" :to="localePath('/case-studies')">
                        {{ $t('case_studies.title') }}
                    </NuxtLink>
                </div>

                <div
                    v-if="!$getIsLangKoZhDe()"
                    class="
                        col-span-12
                        md:col-span-6
                        lg:col-span-3 lg:col-start-4
                        flex flex-col
                        items-start
                        mb-8
                        lg:mb-0
                    "
                >
                    <h3 class="font-bold mb-4 lg:mb-3">
                        <NuxtLink :to="localePath('/job-seeker')">{{ $t('navigation.job_seekers') }}</NuxtLink>
                    </h3>
                    <NuxtLink
                        class="mb-4 lg:mb-3 animated-underline capital-initial"
                        :to="localePath('/job-seeker/jobs')"
                    >
                        {{ $t('jobs.title') }}
                    </NuxtLink>
                    <NuxtLink class="mb-4 lg:mb-3 animated-underline" :to="localePath('/job-seeker/advice')">
                        {{ $t('advice.title') }}
                    </NuxtLink>
                </div>

                <div
                    class="col-span-12 md:col-span-6 flex flex-col items-start mb-8 lg:mb-0"
                    :class="$getIsLangKoZhDe() ? 'lg:col-span-3 lg:col-start-4' : 'lg:col-span-2 lg:col-start-7'"
                >
                    <h3 class="font-bold mb-4 lg:mb-3">
                        <NuxtLink class="animated-underline font-bold" :to="localePath('/contact')">
                            {{ $t('contact.title') }}
                        </NuxtLink>
                    </h3>
                    <NuxtLink class="mb-4 lg:mb-3 animated-underline" :to="localePath('/about-us')">
                        {{ $t('about_us.title') }}
                    </NuxtLink>
                    <NuxtLink
                        v-if="!$getIsLangKoZhDe()"
                        class="mb-4 lg:mb-3 animated-underline"
                        :to="localePath('/news')"
                    >
                        {{ $t('news.title') }}
                    </NuxtLink>
                    <NuxtLink
                        v-if="$i18n.locale === 'en'"
                        class="mb-4 lg:mb-3 animated-underline text-yellow-400"
                        :to="localePath('/golden-visa')"
                    >
                        {{ $t('golden_visa.title') }}
                    </NuxtLink>
                    <NuxtLink class="mb-4 lg:mb-3 animated-underline lg:hidden" :to="localePath('/privacy-policy')">
                        {{ $t('privacy_policy.title') }}
                    </NuxtLink>
                    <NuxtLink class="mb-4 lg:mb-3 animated-underline lg:hidden" :to="localePath('/cookie-policy')">
                        {{ $t('cookie_policy.title') }}
                    </NuxtLink>
                </div>

                <div class="col-span-12 md:col-span-12 lg:col-span-4 lg:col-start-9 items-start">
                    <ContactDetail
                        :icon="require('~/assets/images/icons/location.svg')"
                        :label="$t('generic.company_name')"
                        class="mb-5"
                    >
                        <a
                            href="https://www.google.com/maps?q=Budapest,+Logodi+utca+44/b.+1.+em.+4.&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjkz82Ou5b3AhVLg_0HHX3uCKIQ_AUoAXoECAEQAw"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="desktop:hover:underline my-2 sm:my-0 underline sm:no-underline"
                        >
                            {{ $t('generic.company_address') }}
                        </a>
                    </ContactDetail>

                    <ContactDetail
                        :icon="require('~/assets/images/icons/mail.svg')"
                        :label="$t('contact.email') + ':'"
                        class="mb-5"
                    >
                        <a
                            class="underline sm:no-underline desktop:hover:underline break-all"
                            href="mailto:info@banyaiconsulting.hu?subject=Info"
                        >
                            info@banyaiconsulting.hu
                        </a>
                        <br />
                        <a
                            v-if="!$getIsLangKoZhDe()"
                            class="underline sm:no-underline desktop:hover:underline break-all"
                            href="mailto:palyazat@banyaiconsulting.hu?subject=Pályázat"
                        >
                            palyazat@banyaiconsulting.hu
                        </a>
                    </ContactDetail>

                    <ContactDetail
                        :icon="require('~/assets/images/icons/phone.svg')"
                        :label="$t('contact.phone') + ':'"
                    >
                        <a class="underline sm:no-underline desktop:hover:underline" href="tel:+3619506081">
                            +36 1 950 60 81
                        </a>
                    </ContactDetail>
                </div>
            </div>

            <div class="flex flex-col items-start lg:flex-row lg:items-center justify-between text-sm md:text-base">
                <span> Copyright Bányai & Partners Consulting, {{ $dayjs().year() }} </span>
                <div class="hidden lg:block">
                    <NuxtLink class="animated-underline" :to="localePath('/privacy-policy')">
                        {{ $t('privacy_policy.title') }}
                    </NuxtLink>
                    <span class="mx-4">|</span>
                    <NuxtLink class="animated-underline" :to="localePath('/cookie-policy')">
                        {{ $t('cookie_policy.title') }}
                    </NuxtLink>
                </div>
            </div>

            <div class="flex items-center mt-4">
                <Facebook :url="facebookUrl" class="mr-4" />
                <Twitter :url="twitterUrl" class="mr-4" />
                <Linkedin :url="linkedinUrl" class="mr-4" />
                <Instagram :url="instagramUrl" />
            </div>
        </div>
    </footer>
</template>

<script>
import Facebook from '@/components/UI/Icons/Facebook';
import Linkedin from '@/components/UI/Icons/Linkedin';
import Twitter from '@/components/UI/Icons/Twitter';
import Instagram from '@/components/UI/Icons/Instagram';
import ContactDetail from '@/components/Generic/ContactDetail';

export default {
    name: 'Footer',
    components: {
        Facebook,
        Linkedin,
        Twitter,
        Instagram,
        ContactDetail,
    },
    computed: {
        facebookUrl() {
            return 'http://www.facebook.com/BanyaiConsulting';
        },
        twitterUrl() {
            return 'https://twitter.com/BanyaiPartners';
        },
        linkedinUrl() {
            return 'https://www.linkedin.com/company/b-nyai-&-partners-consulting/';
        },
        instagramUrl() {
            return 'https://www.instagram.com/banyaiconsulting/';
        },
    },
};
</script>

<style scoped></style>
