<template>
    <Portal selector="body">
        <Transition name="fade-up">
            <div
                v-if="$store.state.cookie.isCookieBannerVisible"
                class="
                    fixed
                    bottom-5
                    left-5
                    right-5
                    sm:bottom-0 sm:left-0 sm:right-0
                    bg-white
                    text-black
                    py-4
                    sm:py-8
                    px-4
                    sm:px-12
                    lg:px-24
                    rounded
                    lg:rounded-none
                    z-30
                "
                style="box-shadow: 0 0 14px -2px rgba(0, 0, 0, 0.1)"
            >
                <div class="flex flex-col lg:flex-row items-center">
                    <div class="mb-5 lg:mb-0 sm:mr-14">
                        <span class="font-Abhaya-Libre font-bold text-2xl block mb-2">
                            {{ $t('cookie_banner.title') }}
                        </span>
                        <p>
                            {{ $t('cookie_banner.description') }}
                            <a
                                :href="localePath('/cookie-policy')"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="font-bold underline"
                            >
                                {{ $t('generic.more_information') }}
                            </a>
                        </p>
                    </div>
                    <div
                        class="
                            flex
                            items-stretch
                            xs:items-start
                            lg:items-stretch
                            flex-col
                            md:flex-row
                            lg:flex-col
                            xl:flex-row
                            lg:flex-nowrap lg:justify-end
                            w-full
                        "
                    >
                        <Button
                            :label="$t('cookie_banner.accept_all')"
                            class="my-2 md:m-2 p-button-warning inline-flex justify-center"
                            @click="acceptAll"
                        />
                        <Button
                            :label="$t('cookie_banner.accept_mandatory')"
                            class="p-button-secondary p-button-outlined my-2 md:m-2 inline-flex justify-center"
                            @click="acceptMandatoryOnly"
                        />
                    </div>
                </div>
            </div>
        </Transition>
    </Portal>
</template>

<script>
export default {
    name: 'CookieBar',
    data() {
        return {
            model: {
                necessary: true,
                statistics: true,
                marketing: true,
                preferences: true,
            },
        };
    },
    mounted() {
        const savedConsent = JSON.parse(localStorage.getItem('consent'));

        if (!savedConsent && this.getRouteBaseName() !== 'cookie-policy') {
            setTimeout(() => {
                this.$store.dispatch('cookie/openBanner');
            }, 1000);
        }
    },
    methods: {
        acceptAll() {
            this.model.statistics = true;
            this.model.marketing = true;
            this.model.preferences = true;
            this.updateSelection();
        },
        acceptMandatoryOnly() {
            this.model.statistics = false;
            this.model.marketing = false;
            this.model.preferences = false;
            this.updateSelection();
        },
        updateSelection() {
            // Cookie consent types by categories

            // Necessary
            // - security_storage, functionality_storage
            // since we classify them as mandatory, we do not update their value in the payload

            // Statistics
            // - analytics_storage

            // Preferences
            // - personalization_storage

            // Marketing
            // - ad_storage, ad_user_data, ad_personalization

            // Create a payload from the preference input values consumable by Google Tag Manager
            const payload = {};

            const { statistics, preferences, marketing } = this.model;

            // payload.security_storage = necessary ? 'granted' : 'denied';
            // payload.functionality_storage = necessary ? 'granted' : 'denied';
            payload.analytics_storage = statistics ? 'granted' : 'denied';
            payload.personalization_storage = preferences ? 'granted' : 'denied';
            payload.ad_storage = marketing ? 'granted' : 'denied';
            payload.ad_user_data = marketing ? 'granted' : 'denied';
            payload.ad_personalization = marketing ? 'granted' : 'denied';

            // Update Google Tag Manager with the selected preferences
            this.$gtag('consent', 'update', payload);

            localStorage.setItem('consent', JSON.stringify(this.model));

            this.$store.dispatch('cookie/closeBanner');
        },
    },
};
</script>

<style scoped></style>
