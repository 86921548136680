var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.illustration && !_vm.secondaryType ? 'pb-36 sm:pb-16' : 'pb-16'},[_c('div',{staticClass:"\n            relative\n            h-full\n            pt-24\n            xl:pt-28\n            2xl:pt-32\n            rounded-br-200\n            md:rounded-br-[250px]\n            xl:rounded-br-[350px]\n            purple-gradient-bg\n        ",class:{ 'overflow-hidden': _vm.secondaryType }},[_c('div',{staticClass:"\n                absolute\n                w-full\n                h-full\n                top-0\n                left-0\n                bg-cover bg-no-repeat bg-center\n                z-0\n                rounded-br-200\n                md:rounded-br-[250px]\n                xl:rounded-br-[350px]\n            ",class:{ 'golden-visa-bg': _vm.isGoldenVisaPage },style:(("background-image: url(" + (_vm.backgroundImage || require('assets/images/illustrations/logo-as-hero-bg-shape.svg')) + ");"))}),_vm._v(" "),_c('div',{staticClass:"relative container grid grid-cols-12 gap-5 z-5",class:{ 'pb-12 sm:pb-20 lg:pb-24': _vm.overlappedByContent }},[_c('div',{staticClass:"col-span-12",class:[
                    _vm.illustration ? 'lg:pb-28  lg:pr-0 sm:pb-18' : 'pb-18 sm:pr-0',
                    _vm.illustration && !_vm.secondaryType ? 'sm:pr-20' : '',
                    _vm.secondaryType ? 'sm:col-span-6 lg:col-span-7 lg:pr-12' : 'sm:col-span-8 pr-12' ]},[_c('h1',{staticClass:"aux-text mt-4 sm:mt-8 xl:mt-12 2xl:mt-16 mb-4 transform-all",class:[!_vm.mounted && _vm.isIndexPage ? 'translate-y-4 opacity-0' : ''],staticStyle:{"transition-duration":"500ms","transition-delay":"50ms"}},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                ")]),_vm._v(" "),_c('h2',{staticClass:"title title-xl",class:[
                        _vm.isGoldenVisaPage
                            ? 'w-72 lg:w-auto lg:max-w-sm lg:pr-8 xl:max-w-md 2xl:pr-0'
                            : _vm.illustration
                            ? 'max-w-3xl'
                            : 'max-w-4xl',
                        !_vm.mounted && _vm.isIndexPage ? 'translate-y-4 opacity-0' : '' ],staticStyle:{"transition-duration":"500ms","transition-delay":"100ms"}},[_vm._v("\n                    "+_vm._s(_vm.lead)+"\n                ")]),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"mt-6 max-w-3xl",class:{
                        'translate-y-4 opacity-0': !_vm.mounted && _vm.isIndexPage,
                        'pr-20': !_vm.secondaryType,
                        'xl:max-w-2xl': _vm.isIndexPage,
                        'pr-12 sm:pr-0 sm:max-w-md': _vm.isGoldenVisaPage,
                    },staticStyle:{"transition-duration":"500ms","transition-delay":"150ms"}},[_vm._v("\n                    "+_vm._s(_vm.description)+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.primaryButton || _vm.secondaryButton)?_c('div',{staticClass:"flex flex-col items-start lg:flex-row mt-8 sm:mt-16 lg:mt-24"},[(_vm.primaryButton)?_c('NuxtLink',{staticClass:"p-component p-button p-button-secondary",class:{
                            'mb-5 lg:mb-0 lg:mr-5': _vm.secondaryButton,
                            'translate-y-4 opacity-0': !_vm.mounted && _vm.isIndexPage,
                        },style:(_vm.buttonsHaveDelayedAnimation ? "transition-duration: 500ms; transition-delay: 150ms" : ''),attrs:{"to":_vm.primaryButton.url}},[_vm._v("\n                        "+_vm._s(_vm.primaryButton.label)+"\n                    ")]):_vm._e(),_vm._v(" "),(_vm.secondaryButton)?_c('NuxtLink',{staticClass:"p-component p-button p-button-secondary p-button-outlined p-button-light",class:{ 'translate-y-4 opacity-0': !_vm.mounted && _vm.isIndexPage },style:(_vm.buttonsHaveDelayedAnimation ? "transition-duration: 500ms; transition-delay: 200ms" : ''),attrs:{"to":_vm.secondaryButton.url}},[_vm._v("\n                        "+_vm._s(_vm.secondaryButton.label)+"\n                    ")]):_vm._e()],1):_vm._e()]),_vm._v(" "),_vm._t("right"),_vm._v(" "),_c('Transition',{attrs:{"name":"fade-left"}},[(_vm.illustration && _vm.mounted)?_c('div',{staticClass:"relative sm:h-auto col-span-12",class:[
                        _vm.secondaryType
                            ? 'h-72 sm:col-span-6 lg:col-span-5 lg:col-start-8'
                            : 'h-56 sm:col-span-4 sm:col-start-9' ],staticStyle:{"transition-duration":"700ms","transition-timing-function":"cubic-bezier(0.23, 0.15, 0, 1)"}},[_c('div',{staticClass:"pl-2 sm:pl-0 sm:absolute sm:top-0 sm:left-0 w-full",class:[_vm.secondaryType ? 'h-full' : 'hero-illustration md:pb-28 xl:pb-0']},[_c('div',{staticClass:"\n                                relative\n                                h-full\n                                md:max-h-[400px]\n                                lg:max-h-[unset]\n                                xs:max-w-[300px]\n                                md:max-w-[500px]\n                            ",class:[_vm.secondaryType ? 'max-h-[360px]' : 'max-h-[340px]']},[_c('div',{staticClass:"absolute w-full h-full bg-left-bottom bg-contain bg-no-repeat z-10",style:(("background-image: url(" + _vm.illustration + ")"))}),_vm._v(" "),(_vm.decoration)?_c('div',{staticClass:"\n                                    absolute\n                                    w-32\n                                    h-22\n                                    sm:w-44 sm:h-32\n                                    bg-left-bottom bg-contain bg-no-repeat\n                                    z-0\n                                ",class:[
                                    _vm.secondaryType
                                        ? 'right-5 xs:right-8 md:right-5 xl:right-12 bottom-28 md:bottom-32 xl:bottom-36'
                                        : '-bottom-8 sm:-bottom-10 -left-3 sm:-left-12' ],style:(("background-image: url(" + _vm.decoration + ")"))}):_vm._e()])])]):_vm._e()])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }